



















































































































































































import {
  defineComponent,
  reactive,
  PropType,
  watch,
  computed,
} from "@vue/composition-api"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue"
import TextField from "@/components/textFields/TextField.vue"
import TextArea from "@/components/textAreas/TextArea.vue"
import TextButton from "@/components/buttons/TextButton.vue"
import { TableData } from "./PersonalConfirmMemo.vue"
import DefaultButton from "@/components/buttons/DefaultButton.vue"
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue"
import { workflowContainer } from "@/containers/WorkflowContainer"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import { workflowOperationText } from "@/models/Workflow"
import DateTime from "@/models/DateTime"

interface State {
  withdrawal: boolean
  isLoading: boolean
  item: TableData
  isFormValid: boolean
  comment: string
}

export default defineComponent({
  props: {
    tableItem: Object as PropType<TableData>,
    visible: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    I18nFormattedMessage,
    DefaultDialog,
    TextField,
    TextArea,
    TextButton,
    DefaultButton,
    ConfirmDialog,
  },
  setup(props, { emit }) {
    const state = reactive<State>({
      withdrawal: false,
      isLoading: false,
      item: {} as TableData,
      isFormValid: false,
      comment: "",
    })

    const {
      approveWorkflow,
      patchWithdrawal,
      createApprovedPdf,
    } = workflowContainer.useContainer()
    const { getContext } = teamsContextContainer.useContainer()

    const hasTeamsInfo = computed<boolean>(
      () =>
        !!(props.tableItem && props.tableItem.team && props.tableItem.channel)
    )

    const onOk = async (approve: boolean) => {
      if (!props.tableItem) throw new Error("tableItemはない")
      if (!hasTeamsInfo.value) throw new Error("Teams情報はない")

      state.isLoading = true
      const context = await getContext()
      const isCompleted = state.item.approvers.every(a =>
        a.id === context.userObjectId ? approve : a.operation === 1
      )
      let fileUrl: string | null = null
      if (isCompleted) {
        const driveItem = await createApprovedPdf(
          state.item.workflowId,
          `議事録_${state.item.meetingStructure}_${state.item.meetingStartDate}_${state.item.meetingId}.pdf`,
          state.item.approvers.map(a => ({
            name: a.name,
            date: (
              a.operateAt || new DateTime(new Date())
            ).toDateJpStringFull(),
          }))
        )
        fileUrl = driveItem?.webUrl || ""
      }
      const workflowIn = await approveWorkflow(
        props.tableItem.workflowId,
        approve,
        state.comment,
        props.tableItem.team,
        props.tableItem.channel,
        fileUrl
      )
      state.isLoading = false
      emit("close", workflowIn)
    }

    const onWithdrawn = async () => {
      if (!props.tableItem) throw new Error("tableItemはない")
      state.isLoading = true
      state.withdrawal = false
      const workflowIn = await patchWithdrawal(props.tableItem.workflowId)
      state.isLoading = false
      emit("close", workflowIn)
    }

    const onCancel = () => {
      emit("close")
    }

    const onDownloadPdf = () => {
      window.open(state.item.filePath)
    }

    watch(
      () => props.visible,
      visible => {
        if (visible) {
          state.item = Object.assign({}, props.tableItem)
        }
      }
    )

    watch(
      () => props.tableItem,
      tableItem => {
        if (tableItem) {
          state.item = Object.assign({}, props.tableItem)
        }
      },
      { deep: true }
    )

    return {
      state,
      hasTeamsInfo,
      onOk,
      onCancel,
      onDownloadPdf,
      onWithdrawn,
      workflowOperationText,
    }
  },
})
