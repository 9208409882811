





























































































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
} from "@vue/composition-api"
import { TypedComponentProps } from "@/vue-props-types"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import DefaultButton from "@/components/buttons/DefaultButton.vue"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import { workflowContainer } from "@/containers/WorkflowContainer"
import WithBackPageLayout from "@/layouts/WithBackPageLayout.vue"
import Card from "@/components/cards/Card.vue"
import TextField from "@/components/textFields/TextField.vue"
import ApproveDialog from "./ApproveDialog.vue"
import TableHeader from "@/utilities/TableHeader"
import DateTime from "@/models/DateTime"
import IconButton from "@/components/buttons/IconButton.vue"
import CalendarMenu from "@/components/pickDateTime/CalendarMenu.vue"
import Dropdown, { Option } from "@/components/dropdowns/Dropdown.vue"
import { WorkflowIn, workflowStatusText } from "@/models/Workflow"
import { projectsContainer } from "@/containers/ProjectsContainer"
import { Meeting, MeetingStructure } from "@/models/Meeting"
import TeamsContext from "@/models/TeamsContext"
import { parse } from "json2csv"
import SofieTokenProvider from "@/utilities/SofieTokenProvider"
import { Project } from "@/models/Project"
import { Channel, Group } from "@microsoft/microsoft-graph-types"

const sofieTokenProvider = new SofieTokenProvider()

interface Props {}
export interface ApproveData {
  id: string
  name: string
  operation: number
  comment: string
  operateAt: DateTime
}
export interface TableData {
  workflowId: string
  isApplicant: boolean
  isApprovable: boolean
  groupId: string
  channelId: string
  team: string
  channel: string
  requestDateStr: string
  requestDate: DateTime
  applicant: string
  applicantId: string
  statusNum: number
  status: string
  meetingId: string
  meetingStructure: string
  meetingStructureId: string
  filePath: string
  comment: string
  approversStr: string
  approvers: Array<ApproveData>
  meetingStartDate: string
}

interface State {
  isAdmin: boolean
  isAdminMode: boolean
  isProcessing: boolean
  loading: boolean
  status: number
  tableItems: Array<TableData>
  tableItemsAll: Array<TableData>
  startDate: Date
  endDate: Date
  allUsers: { [key: string]: string | null }
  selectedItem: TableData | null
  projects: Array<{
    project: Project
    meetings: MeetingStructure[]
  }>
  teams: Array<{
    team: Group
    channels: Channel[]
  }>
}

const propsOptions = {
  id: {
    type: String,
    required: true,
  },
}

export default defineComponent<TypedComponentProps<Props, typeof propsOptions>>(
  {
    props: propsOptions,
    components: {
      I18nFormattedMessage,
      DefaultButton,
      WithBackPageLayout,
      Card,
      TextField,
      IconButton,
      CalendarMenu,
      Dropdown,
      ApproveDialog,
    },
    setup() {
      const tableHeaders = computed<Array<TableHeader>>(() => [
        { text: "起案日時", value: "requestDateStr" },
        { text: "起案者", value: "applicant" },
        { text: "確認者", value: "approversStr" },
        { text: "ステータス", value: "status" },
        { text: "チーム", value: "team" },
        { text: "チャネル", value: "channel" },
        { text: "会議体", value: "meetingStructure" },
        { text: "開催日", value: "meetingStartDate" },
        { text: "", value: "action" },
      ])
      const statusItems = computed<Array<Option>>(() => [
        { text: "", value: -1 },
        { text: "処理中", value: 0 },
        { text: "承認", value: 1 },
        { text: "却下", value: 2 },
        { text: "取下げ", value: 3 },
      ])

      const state = reactive<State>({
        isProcessing: true,
        isAdmin: false,
        isAdminMode: false,
        status: -1,
        loading: true,
        tableItems: [],
        tableItemsAll: [],
        allUsers: {},
        startDate: new DateTime(new Date()).add({ months: -1 }).toJsDate(),
        endDate: new DateTime(new Date()).toJsDate(),
        selectedItem: null,
        projects: [],
        teams: [],
      })

      const {
        getContext,
        getUser,
        getMyTeams,
        getTeams,
      } = teamsContextContainer.useContainer()
      const { getWorkflowsAsync } = workflowContainer.useContainer()
      const {
        getMyProjectsAsync,
        getAdminProjectsAsync,
      } = projectsContainer.useContainer()
      const onRowClicked = async (item: TableData) => {
        state.selectedItem = item
      }
      const onRowDownloadBtnClick = async (item: TableData) => {
        window.open(item.filePath)
      }

      const getAllUserName = async () => {
        const users = await Promise.all(
          Object.keys(state.allUsers)
            .filter(u => state.allUsers[u] === null)
            .map(currentKey => getUser(currentKey))
        )
        for (const user of users) {
          state.allUsers[user?.id || ""] = user?.displayName || ""
        }
        state.tableItems = state.tableItems.map(t =>
          Object.assign(t, {
            applicant: state.allUsers[t.applicantId],
            approvers: t.approvers.map(a =>
              Object.assign(a, { name: state.allUsers[a.id] })
            ),
            approversStr: t.approvers.reduce((a, b) => {
              a += `${state.allUsers[b.id]}; `
              return a
            }, ""),
          })
        )
      }

      const setProjectDatas = async () => {
        if (state.isAdminMode) {
          const othersGroupIds = state.tableItems
            .filter(
              t => !state.projects.some(p => p.project.groupId === t.groupId)
            )
            .map(n => n.groupId)
          const otherProject = othersGroupIds.length
            ? await getAdminProjectsAsync(othersGroupIds)
            : []
          state.projects = state.projects.concat(otherProject)
        } else if (state.projects.length === 0) {
          state.projects = await getMyProjectsAsync()
        }
        // 対象の会議に絞る
        const projects = state.projects.filter(
          p =>
            state.tableItems.some(ti => ti.groupId === p.project.groupId) &&
            state.tableItems.some(ti =>
              p.meetings.some(m => ti.meetingStructureId === m.id)
            )
        )
        // 会議体系の情報
        const meetingStructures = projects.reduce((a, b) => {
          return a.concat(b.meetings)
        }, [] as MeetingStructure[])
        // 会議の情報
        const meeting = projects
          .reduce((a, b) => {
            return a.concat(b.meetings)
          }, [] as MeetingStructure[])
          .reduce((a, b) => {
            return a.concat(b.meetings)
          }, [] as Meeting[])
        state.tableItems = state.tableItems.map(t =>
          Object.assign(t, {
            meetingStructure:
              meetingStructures.find(ms => ms.id === t.meetingStructureId)
                ?.name ?? "(取得できませんでした)",
            meetingStartDate:
              meeting
                .find(ms => ms.id === t.meetingId)
                ?.startTime.toDateJpString() ?? "(取得できませんでした)",
          })
        )
      }

      const setTeamDatas = async () => {
        if (state.isAdminMode) {
          const otherTeams = await getTeams(
            state.tableItems
              .filter(
                n => !state.teams.some(t => t.team.team?.id === n.groupId)
              )
              .map(n => n.groupId)
          )
          state.teams = state.teams.concat(otherTeams)
        } else {
          state.teams = await getMyTeams(state.tableItems.map(n => n.groupId))
        }

        state.tableItems = state.tableItems.map(table => {
          const team = state.teams.find(t => t.team.id === table.groupId)
          return Object.assign(table, {
            team: team?.team.displayName,
            channel: team?.channels.find(c => c.id === table.channelId)
              ?.displayName,
          })
        })
      }

      const sort = async (addSelectedItem: boolean) => {
        state.loading = true
        const context = await getContext()
        const start = new Date(new DateTime(state.startDate).toDateString())
        const end = new Date(
          new DateTime(state.endDate).add({ days: 1 }).toDateString()
        )
        state.tableItems = state.tableItemsAll.filter(a => {
          const approver = a.approvers.find(
            ap => ap.id === context.userObjectId
          )
          return (
            (!state.isProcessing ||
              (state.isProcessing &&
                approver &&
                approver?.operation === 0 &&
                a.statusNum === 0) ||
              (state.isProcessing && !approver && a.statusNum === 0)) &&
            a.requestDate.isSameOrAfter(start, "minutes") &&
            a.requestDate.isBefore(end, "minutes") &&
            (state.status === -1 || state.status === a.statusNum)
          )
        })
        if (
          state.selectedItem &&
          addSelectedItem &&
          !state.tableItems.some(
            t => t.workflowId === state.selectedItem?.workflowId
          )
        ) {
          state.tableItems = state.tableItems.concat([state.selectedItem])
        }
        state.tableItems.forEach(item => {
          if (!state.allUsers[item.applicantId])
            state.allUsers[item.applicantId] = null
          item.approvers.forEach(ap => {
            if (!state.allUsers[ap.id]) state.allUsers[ap.id] = null
          })
        })
        await Promise.all([getAllUserName(), setTeamDatas(), setProjectDatas()])
        state.loading = false
      }
      const setStartDate = async (start: string) => {
        state.startDate = new Date(start)
        await sort(false)
      }
      const setEndDate = async (end: string) => {
        state.endDate = new Date(end)
        await sort(false)
      }

      const dropdownChange = async (value: number) => {
        state.status = value
        await sort(false)
      }

      const setProcessing = async (value: boolean) => {
        state.isProcessing = value
        await sort(false)
      }

      const convertWorkflow = (
        context: TeamsContext,
        item: WorkflowIn
      ): TableData => {
        const isApprover = item.approvers.find(
          a => a.userId == context.userObjectId
        )
        return {
          workflowId: item.id,
          comment: item.applicantComment,
          groupId: item.groupId,
          channelId: item.channelId,
          team: "...",
          channel: "...",
          requestDateStr: item.createdAt.toDateStringSlashFull(),
          requestDate: item.createdAt,
          isApplicant: item.applicantId === context.userObjectId,
          isApprovable:
            !!isApprover && isApprover.operation === 0 && item.status === 0,
          applicant: "...",
          applicantId: item.applicantId,
          statusNum: item.status,
          status: workflowStatusText[item.status],
          meetingId: item.meetingId,
          meetingStructure: "...",
          meetingStructureId: item.meetingStructureId,
          filePath: item.filePath,
          approversStr: "...",
          approvers: item.approvers.map(ap => {
            return {
              id: ap.userId,
              name: "",
              operation: ap.operation,
              comment: ap.comment,
              operateAt: ap.operateAt,
            } as ApproveData
          }),
          meetingStartDate: "...",
        }
      }

      const onInit = async () => {
        const context = await getContext()
        const items = await getWorkflowsAsync(state.isAdmin)
        const roles = await sofieTokenProvider.getRoles()
        state.isAdmin = !!roles && roles.some(r => r === "Admin")
        state.tableItemsAll = items.map(item => convertWorkflow(context, item))
        if (context.subEntityId) {
          state.selectedItem =
            state.tableItemsAll.find(
              t => t.workflowId === context.subEntityId
            ) || null
        }
        await sort(true)
        state.loading = false
      }

      onMounted(async () => {
        await onInit()
      })

      const Close = async (workflow: WorkflowIn) => {
        if (workflow) {
          const context = await getContext()
          const wf = convertWorkflow(context, workflow)
          let table: TableData | undefined = state.tableItems.find(
            t => t.workflowId === workflow.id
          )
          if (table) {
            const newApprover = wf.approvers.find(
              a => a.id === context.userObjectId
            )
            const currentApprover = table.approvers.find(
              a => a.id === context.userObjectId
            )
            let approvers = table.approvers
            if (currentApprover) {
              const approver = Object.assign(currentApprover, {
                operation: newApprover?.operation,
                comment: newApprover?.comment,
              })
              approvers = table.approvers.map(a =>
                a.id === context.userObjectId ? approver : a
              )
            }
            table = Object.assign(table, {
              status: wf.status,
              isApprovable: wf.isApprovable,
              approvers,
            }) as TableData
            state.tableItems = state.tableItems.map(t =>
              t.workflowId === workflow.id && table ? table : t
            )
          }
          await sort(false)
        }
        state.selectedItem = null
      }

      const changeAdminMode = async (newValue: boolean) => {
        state.isAdminMode = newValue
        if (newValue) state.isProcessing = false
        await onInit()
      }

      const downloadToCsv = () => {
        const fields = [
          {
            label: "起案日時",
            value: "requestDateStr",
          },
          {
            label: "起案者",
            value: "applicant",
          },
          {
            label: "確認者",
            value: "approversStr",
          },
          {
            label: "ステータス",
            value: "status",
          },
          {
            label: "チーム",
            value: "team",
          },
          {
            label: "チャネル",
            value: "channel",
          },
          {
            label: "会議体",
            value: "meetingStructure",
          },
          {
            label: "開催日",
            value: "meetingStartDate",
          },
        ]
        const opts = { fields }
        const urlKit = window.URL || window.webkitURL
        const filename = `議事確認依頼一覧_(${new DateTime(
          state.startDate
        ).toDateJpString()}～${new DateTime(
          state.endDate
        ).toDateJpString()}).csv`
        const csv = parse(state.tableItems, opts)
        const bom = new Uint8Array([0xef, 0xbb, 0xbf])
        const blob = new Blob([bom, csv], { type: "text/csv" })
        const url = urlKit.createObjectURL(blob)
        const download = document.createElement("a")
        download.href = url
        download.download = filename
        download.click()
        urlKit.revokeObjectURL(url)
      }

      return {
        state,
        tableHeaders,
        statusItems,
        setStartDate,
        setEndDate,
        onRowClicked,
        onRowDownloadBtnClick,
        Close,
        dropdownChange,
        changeAdminMode,
        downloadToCsv,
        setProcessing,
      }
    },
  }
)
